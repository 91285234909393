<template>
  <v-card :flat="embedded">
    <v-container fluid :class="embedded ? 'pa-0' : null">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col class="text-center" cols="12">
                  <h3>Checking payment records</h3>
                </v-col>
                <v-col cols="12">
                  <v-progress-circular color="color3" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>

            <v-container fluid v-else-if="amountAvailable === 0">
              <v-row dense>
                <v-col class="text-center" >
                  <h3>According to our records this item is already fully refunded</h3>
                </v-col>
              </v-row>
            </v-container>

            <v-container fluid v-else-if="refundAvailable">
              <v-row dense>
                <v-col cols="12">
                  <h3>Issue a refund</h3>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    dense
                    label="Reason"
                    :items="reasons"
                    item-text="text"
                    item-value="value"
                    v-model="reason"
                    hide-details
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-radio-group v-model="full" hide-details row>
                    <v-radio
                      label="Full"
                      :value="true"
                      color="color3"
                    ></v-radio>
                    <v-radio
                      label="Partial"
                      :value="false"
                      color="color3"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense v-if="!full">
                <v-col>
                  <v-text-field
                    dense
                    label="Amount to refund"
                    prefix="$"
                    v-model="amount"
                    type="number"
                    :hint="`Must be between $0 and ${$options.filters.usDollars(amountAvailable)}`"
                    persistent-hint
                    color="color3"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    label="Notes"
                    hint="These notes are for your reference. i.e. Why the customer asked for a refund"
                    persistent-hint
                    v-model="note"
                    color="color3"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    color="color3"
                    label="I authorize this refund and understand it can not be undone."
                    v-model="confirm"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-btn
                  color="color3 color3Text--text"
                  :disabled="!confirm"
                  :loading="working"
                  @click="issueRefund"
                >Issue Refund</v-btn>
              </v-row>
            </v-container>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import sum from 'lodash.sum'

export default {
  props: ['charge', 'loading', 'bagItemId', 'embedded'],
  data () {
    return {
      reason: 'requested_by_customer',
      full: true,
      amount: null,
      note: null,
      confirm: false,
      reasons: [
        {
          text: 'Customer requested',
          value: 'requested_by_customer'
        },
        {
          text: 'Duplicate transaction',
          value: 'duplicate'
        }
      ],
      working: false
    }
  },
  computed: {
    refundAvailable () {
      if (!this.charge || !this.charge.id) return false
      return this.amountAvailable > 0
    },
    amountRefunded () {
      return this.charge ? sum(this.charge.refunds.map((r) => r.amount)) : 0
    },
    amountAvailable () {
      return this.charge ? this.charge.amount - this.amountRefunded : 0
    },
    dto () {
      return {
        bagItemId: this.bagItemId,
        reason: this.reason,
        vblReason: this.note,
        amount: this.full ? null : this.amount
      }
    }
  },
  methods: {
    issueRefund () {
      this.working = true
      this.$VBL.cart.refund(this.dto)
        .then(r => {
          this.$emit('refund-complete', r.data)
          this.reset()
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.working = false
        })
    },
    reset () {
      this.reason = 'requested_by_customer'
      this.full = true
      this.amount = null
      this.note = null
      this.confirm = false
    }
  },
  watch: {
    working: function (val) {
      this.$emit('busy-change', val)
    }
  }
}
</script>

<style>

</style>
